h1 {
    size: 16pt;
    text-align: center;
    padding: 1em 0;
    color: #9f334a;
}

h2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 22pt;
    color: #ff9200;
}

h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 20pt;
    color: #ff9200;
    text-transform: uppercase;
}

h4 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 14pt;
    color: #bc6c01;
    margin-top: 10px;
    text-transform: uppercase;
}

.container p {
    color: #9d9292;
}

.services a {
    color: #5d6166;
    text-decoration: none;
}

.services a:hover {
    color: #ff0000;
    text-decoration: none;
}

.services ul {
    padding-left: 20px;
    list-style: none;
}

.services li {
    margin-bottom: 10px;
    font: 300 15px/20px "Open Sans", Arial, sans-serif;
}

.services li:before {
    font-family: 'Font Awesome 5 Free';
    content: '\f111';
    color: #ff9200;
    margin-right: 12px;
}

@media (max-width: 978px) {
    .container {
        padding: 0;
        margin: 0;
    }

    footer .container {
        padding-right: 15px;
        padding-left: 15px;
    }

    .section {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.blog-entry {
    display: inline-block;
    padding-bottom: 1em;
    color: #5d6166;
}

.author {
    margin-left: 1em;
}

.spacer {
    height: 4em;
    background-color: #ffffff;
}

.btn-default {
    border-radius: 0;
}

#logo {
    color: #9f334a;
    float: left;
    padding-right: 1.5em;
    padding-bottom: 1em;
    line-height: 1;
}

#title {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 24px;
    color: #9f334a;
    text-transform: uppercase;
    line-height: 1.2;
    margin-top: 34px;
}

#menu a.btn-default {
    height: 4em;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #9f334a;
    border-color: #8f2d42;
    padding-top: 1.2em;
}

@media (max-width: 378px) {
    #menu a.btn-default {
        font-weight: 400;
        font-size: 10px;
    }
}

#menu .btn-group-justified {
    table-layout: auto;
}

#middle {
    background-color: #f7f8f8;
}

#tag-line {
    text-align: center;
    padding-top: 2em;
    font-size: 14pt;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    line-height: 1.5;
    margin: 0;
}

.section {
    padding-bottom: 2em;
}

.summary {
    margin-top: 2em;
}

.summary i {
    font-size: 50pt;
    color: gray;
}

.more-info:hover {
    background: #000;
    color: #9f334a;
}

.button {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    color: #ffffff;
    background-color: #9f334a;
    border: none;
}

.laura img {
    margin-top: 20px;
    max-width: 90%;
}

#lower {
    background-color: #ffffff;
    padding-bottom: 1em;
}

#lower .col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9 {
    padding-left: 0;
    padding-right: 0;
}

#lower .row {
    margin: 0;
}

.services {
    padding-left: 0;
}

.services h2 {
    color: #48474d;
}

.body {
    background-color: #f7f8f8;
    padding: 2% 4% 4% 4%;
}

.blog {
    min-height: 10em;
}

.date {
    text-align: center;
}

.date span {
    font-weight: 300;
    font-size: 35px;
    font-family: 'Open Sans', Arial, sans-serif;
    display: inline-block;
    color: #9f334a;
    margin-left: 3px;
}

.staff {
    padding: 1em 0;
    clear: both;
}

.bio {
    padding-left: 3em;
}

footer {
    padding-top: 1em;
    padding-bottom: 3em;
    background-color: #4d5156;
    color: lightgrey;
}

footer .container p {
    font-size: 13pt;
    margin: 0;
}

footer .container a {
    color: #3e9def;
}

.contact {
    margin-top: 10px;
}

#contact {
    /*width: 500px;*/
    padding: 2em 0 2em 0;
}

footer .links a {
    font-size: 36pt;
    color: #000000;
    margin-right: 3px;
}

footer .links a:hover {
    color: #9f334a;
}

.container table {
    margin: 0 auto;
}

.container td,
.container th {
    border: 1px solid #ddd;
    padding: 8px;
}

.container tr:nth-child(even) {
    background-color: #f2f2f2;
}

.container tr:hover {
    background-color: #ddd;
}

.container th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #9f334a;
    color: white;
}

body.index {
    text-align: center;
    color: black;
    font-family: 'Open Sans', sans-serif;
}

body.index h1 {
    padding: 1em 0;
    font-size: 3em;
}

body.index h2 {
    font-weight: 700;
    font-size: 24px;
}

body.index a {
    color: blue;
    text-decoration: underline;
}

body.index a:visited {
    color: purple;
    text-decoration: underline;
}
























#guide {
    display: block;
    margin: auto;
}

#directory {
    margin: 20px;
}

#directory nav {
    text-align: center;
}

#directory nav a {
    margin: 0 2em;
}

#directory table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

#directory thead tr {
    background-color: #009879;
    color: #ffffff;
    padding: 0.5em;
}

#directory thead th {
    padding: 0.5em;
    text-align: center;
}

#directory tbody tr {
    border-bottom: 1px solid #dddddd;
}

#directory tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

#directory tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

#directory tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

#directory td {
    padding: 0.5em;
}

#directory h1 {
    color: #000;
    padding: 0;
}

#directory select,
#directory input {
    color: #000;
}

span {
    padding: 1em;
}

input {
    width: 100%;
}

.navigation {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

#navigation>div {
    width: 100px;
    height: 100px;
}

.pagination {
    text-align: center;
}

.pagination button {
    width: 3em;
}

.pagination ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: inline-block;
}

.pagination ul li {
    padding: 0 0.1em;
    display: inline-block;
}

label {
    margin: 0.5em;
}